<template>
  <div id="section2">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container>
          <br>
            <b-row align-h="around" >
              <b-col md="4" sm="12" class="text-center"
                v-for="(item, idx) in payList" :key="idx">
                <div class="text-center">
                  <b-img :src="payListI[idx].btnImg"/>
                  <br>
                  <h3>
                    {{item.titulo}}
                  </h3>
                  <p>
                    {{item.texto}}
                  </p>
                  <br />
                  <div class="text-center">
                    <a @click="menuClicked(item.url)" class="btn buttonTurqueza" >
                      {{item.btn}}
                    </a>
                  </div>
                </div>
              </b-col>
            </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payList": [
      {
        "titulo": "Store POS payments"
        , "texto": "Learn about the many possibilities we offer you to advance your business."
        , "btn": "INFORM YOU"
        , "url": "page_pay1.1"
      },
      {
        "titulo": "Online payments"
        , "texto": "The solution you are looking for to accept online payments worldwide."
        , "btn": "INFORM YOU"
        , "url": "page_pay1.2"
      },
      {
        "titulo": "Payments per switchboard"
        , "texto": "Instantly and with total security, accept payments via telephone, email and with payment button."
        , "btn": "INFORM YOU"
        , "url": "page_pay1.3"
      }
    ]
  },
  "es":{
    "payList": [
      {
        "titulo": "Pagos con TPV tienda"
        , "texto": "Conoce las múltiples posibilidades que ponemos a tu disposición para hacer crecer tu negocio."
        , "btn": "INFORMÁTE"
        , "url": "page_pay1.1"
      },
      {
        "titulo": "Pagos online"
        , "texto": "La solución que buscas para aceptar pagos por internet en todo el mundo."
        , "btn": "INFORMÁTE"
        , "url": "page_pay1.2"
      },
      {
        "titulo": "Pagos por centralita"
        , "texto": "Al instante y con total seguridad, acepta pagos vía teléfono, email y con botón de pago."
        , "btn": "INFORMÁTE"
        , "url": "page_pay1.3"
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payListI: [
        {
          btnImg: require("@/assets/images/pay/icono_soluciones_02.png")
        },
        {
          btnImg: require("@/assets/images/pay/icono_soluciones_01.png")
        },
        {
          btnImg: require("@/assets/images/pay/icono_soluciones_03.png")
        }
      ]
    };
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  }
};
</script>