<template>
  <div id="section1">
    <div class="fondoFwhite" :style="'background-image: url(' + payImg + ');'">  
      <b-container fluid>
        <b-container>
          <br />
          <br />
          <br />
          <h1>{{$t("paytitle")}}</h1>
          <br />
          <h4>{{$t("paytext")}}</h4>
          <br />
          <br />
          <br />
        </b-container>
      </b-container>
    </div>      
  </div>
</template>

<i18n>
{
  "en":{
    "paytitle": "With our card payment solutions, you do not need to put limits to your business.",
    "paytext": "Learn about the many possibilities we offer you to advance your business."
  },
  "es":{
    "paytitle": "No pongas límites a tu negocio con nuestras soluciones de pago con tarjeta.",
    "paytext": "Conoce las múltiples posibilidades que ponemos a tu disposición para hacer crecer tu negocio."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/img_header_nuestrasoluciones.jpg")
    }
  }
};
</script>
